#root {
    --color-bg-background: #f3f7fb;
    --color-bg-primary: #ffffff;
    --color-bg-secondary: #083e58;
    --color-bg-secondary-darken: #052a3c;
    --color-bg-tertiary: #f3f7fb;
    --color-text-primary: #083e58;
    --color-text-secondary: #000000;
    --color-border-primary: #dededede;
    --color-border-secondary: #f3f7fb;
    --color-border-tertiary: #5994fe;
}
