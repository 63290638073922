$overlayResizeHandleWidth: 5px;
$blue: #083e58;
$lightblue: #0e6f9e;
$gray: #dededede;
$lightgray: #f3f7fb;

.previewScreen__player {
    background: $lightgray;
    border: 1px solid $gray;
    box-sizing: border-box;
    // $width: 50%;
    // width: $width;
    // height: calc($width * 56);
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

// Preview overlays
.previewScreen__overlays {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.preview__overlay {
    overflow: hidden;
    transition: none;
    padding: $overlayResizeHandleWidth;
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 25%;
    background: #ffffff;
    cursor: move;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
}

// Preview overlay text
.preview__overlayText {
    // Alignment
    &--alignLeft {
        text-align: left;
    }

    &--alignRight {
        text-align: right;
    }

    &--alignCenter {
        text-align: center;
    }

    // Themes
    &.overlay-html--redblue {
        color: red;
        background: blue;
    }

    &.overlay-html--whiteorange {
        color: #fff;
        background: #ff6600;
    }

    // TextMode
    &--textMode div {
        background: rgba(255, 255, 255, 0.2);
    }
}

.preview__overlayContent {
    padding: 10px;
    overflow: hidden;
}

// Overlay resizer

.overlay__resizeHandle {
    position: absolute;
    background: blue;
    z-index: 100;

    &--left {
        left: 0;
        top: 0;
        height: 100%;
        width: $overlayResizeHandleWidth;
        cursor: ew-resize;
    }

    &--right {
        right: 0;
        top: 0;
        height: 100%;
        width: $overlayResizeHandleWidth;
        cursor: ew-resize;
    }

    &--top {
        top: 0;
        left: 0;
        height: $overlayResizeHandleWidth;
        width: 100%;
        cursor: ns-resize;
    }

    &--bottom {
        bottom: 0;
        left: 0;
        height: $overlayResizeHandleWidth;
        width: 100%;
        cursor: ns-resize;
    }
}
