@import "colors.scss";
@import "tailwind.scss";
@import "./components/preview";

* {
    font-family: "Poppins", sans-serif;
    transition: 0.1s;
}

body {
    height: 100vh;
    background: var(--color-bg-background);

    #root {
        height: 100vh;
    }
}

#noscript-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: var(--color-bg-background);
}

#noscript-content {
    color: var(--color-text-primary);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
}

.form-control {
    display: block;
    padding: 0.375rem 0.75rem;
    background-color: #ffffff;
    color: #111827;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    width: 100%;
    border-radius: 0.25rem;
    border-color: var(--color-border-primary);
    border-width: 1px;
    appearance: none;
}

.activeScene {
    border-width: 3px;
    border-style: solid;
    border-color: red;
    box-shadow: 0px 0px 25px red;
}

.activeOverlay {
    border-width: 3px;
    border-style: solid;
    border-color: red;
}

.textarea-interaction {
    display: block;
    color: #111827;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    width: 100%;
    border-radius: 0.25rem;
    border-color: var(--color-border-primary);
    border-width: 1px;
    appearance: none;
    color:#444;
    border:1px solid #ccc;
    transition:transform 1s;
}

.highlited-text-interaction{
    color:transparent;
    background-color:#00ff22;
    border-radius:3px;
}

.highlights{
    word-wrap:break-word;
}

.backdrop{
    border:1px solid #ccc;
    transition:transform 1s;
}

.highlight-container{
    transform:translateZ(0);
    -webkit-text-size-adjust:none;
}